import React from 'react'
import linearIconWhite from '../../images/linear-icon-white.svg'
import { SignupBar } from './SignupBar'

export const LinearSignupBar: React.FC = () => (
  <SignupBar
    title="Linear"
    description="Sign up and connect in under a minute"
    iconSrc={linearIconWhite}
    url={process.env.SIGNUP_URL}
  />
)
